import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import currencyFormatter from '@sprinx/formatting/currencyFormatter';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useProductCardAdornmets } from '../../api/products/adornments';
import { ProductCardProduct } from '../@types/ProductCardProduct';

export interface ProductCardTagProps extends StandardDivProps<ProductCardTagClassKey> {
  product: ProductCardProduct;
}

export type ProductCardTagClassKey = 'root' | 'action' | 'new' | 'sale' | 'cashback';

const themeSettings = { name: 'ProductCardTag' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ProductCardTagClassKey, {}>({
      root: {
        fontSize: theme.typography.pxToRem(11),
        color: theme.palette.common.white,
        padding: theme.spacing(0.5, 0),
        textAlign: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: 10,
        width: '43%',

        '&:after': {
          position: 'absolute',
          top: 0,
          right: '-25px',
          content: "''",
          width: 0,
          height: 0,
          borderRight: '25px solid transparent',
        },
        [theme.breakpoints.down('xs')]: {
          width: '50%',
        },
      },
      action: {
        backgroundColor: theme.palette.success.main,
        '&:after': {
          borderBottom: '25px solid',
          borderBottomColor: theme.palette.success.main,
        },
      },
      new: {
        backgroundColor: theme.palette.primary.main,
        '&:after': {
          borderBottom: '25px solid',
          borderBottomColor: theme.palette.primary.main,
        },
      },
      sale: {
        backgroundColor: '#ff9800',
        '&:after': {
          borderBottom: '25px solid',
          borderBottomColor: '#ff9800',
        },
      },
      cashback: {
        width: '33%',
        fontWeight: 500,
        bottom: '30px!important',
        '&:nth-child(3)': {
          bottom: '0px!important',
        },
        backgroundColor: '#ED1C22',
        '&:after': {
          borderBottom: '25px solid',
          borderBottomColor: '#ED1C22',
        },
        [theme.breakpoints.down('xs')]: {
          width: '41%',
        },
      },
    }),
  themeSettings,
);

const adornmentLabel = {
  action: 'Sleva',
  new: 'Novinka',
  sale: 'Výprodej',
  cashback: 'Cashback',
};

const ProductCardTag = React.forwardRef<HTMLDivElement, ProductCardTagProps>(
  ({ className, classes: pClasses, product }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const cardAddornment = useProductCardAdornmets(product);
    const extra = product['extra'];

    if (cardAddornment || (extra && extra.cashBackURL) || cardAddornment || (extra && extra.cashBackURL)) {
      return (
        <>
          {cardAddornment && (
            <div
              ref={ref}
              className={clsx(classes.root, className, {
                [classes.action]: cardAddornment === 'action',
                [classes.new]: cardAddornment === 'new',
                [classes.sale]: cardAddornment === 'sale',
                [classes.cashback]: cardAddornment === 'cashback',
              })}
            >
              {adornmentLabel[cardAddornment] || null}
            </div>
          )}
          {extra && extra.cashBackURL && (
            <div
              ref={ref}
              className={clsx(classes.root, className, classes.cashback, {
                [classes.cashback]: cardAddornment === 'cashback',
              })}
            >
              Cashback {currencyFormatter(extra.cashBackURL, 'CZK', { maximumFractionDigits: 0 })}
            </div>
          )}
        </>
      );
    } else {
      return null;
    }
  },
);

ProductCardTag.displayName = 'ProductCardTag';

export default withThemeProps(themeSettings)(ProductCardTag);
